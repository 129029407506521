.company-name-header {
  /* display: inline; */
  /* margin: auto; */
  font-size: 1.1rem;
  font-weight: 600;
  color: #6495ed;
}

.logo-img {
  margin-right: 1em;
}

.catalogue-name-header {
  /* display: inline; */
  /* margin: auto; */
  font-size: 1rem;
  font-weight: 500;
  color: grey;
}
