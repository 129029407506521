* {
  box-sizing: border-box;
}

.card-tile {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  max-width: 14rem;
  height: 15rem;
}

.profile {
  border-radius: 10px;
  transition: 0.2s;
  height: 12rem;
}

.card-tile:hover .profile {
  transform: scale(1.4);
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.item-title {
  font-size: 1em;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(107, 107, 107);
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-name {
  font-size: 1.2em;
  font-weight: 900;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(107, 107, 107);
}

.product-code {
  font-size: 0.9em;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  color: rgb(107, 107, 107);
  align-items: center;
}

.card-tile .content {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.card-tile .content .back {
  position: absolute;
  width: 100%;
  background-color: #f1f1f1e6;
  transition: 1s;
  height: 100%;
  max-height: 21rem;
  z-index: 1;
  overflow: hidden;
  border-radius: 4px;
  padding: 5px;
}

.from-left {
  top: 0;
  left: -100%;
}

.card-tile:hover .content .from-left {
  left: 0%;
}

.from-bottom {
  top: 100%;
  left: 0;
}
.card-tile:hover .content .from-bottom {
  top: 0%;
}

.from-right {
  top: 0%;
  right: -100%;
}
.card-tile:hover .content .from-right {
  right: 0;
}

.card-tile .content .back h3 {
  font-size: 15px;
  letter-spacing: 2px;
}

.card-tile .content .back .tem-img {
  border-radius: 100%;
  height: 2em;
  width: 2em;
}

.card-tile .content .back .des {
  font-size: 13px;
}

.social-icon {
  position: absolute;
  bottom: 0;
  justify-content: center;
  text-align: center;
  padding-bottom: 1em;
}

.card-tile .content .back .social-icon li {
  display: inline-block;
}

.card-tile-actions {
  display: block;
}

.front{
  height: 100%;
}

.front-effect-checked{
  color: aliceblue;
}

.item-title-checked{
  color: rgb(247, 247, 247);
  display: flex;
  justify-content: center;
}

.for-checkbox{
  display: flex; 
  justify-content:flex-end;
}