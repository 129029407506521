.sc-header {
  background: #6495ed;
  min-height: 75px;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  /* color: white; */
  padding: 10px;
  box-shadow: 0 1px 1px rgb(0, 0, 0, 20%);
  position: relative;
  box-sizing: border-box;
  display: flex;
}

.sc-header--img {
  border-radius: 50%;
  align-self: center;
  padding: 10px;
}

.sc-header--team-name {
  align-self: center;
  padding: 10px;
  flex: 1;
  user-select: none;
  border-radius: 5px;
  color: #fff;
}

.sc-header--close-button {
  width: 45px;
  align-self: center;
  height: 45px;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
}

/* .sc-header--close-button:hover {
  background: #6495ed;
} */

.sc-header--close-button svg {
  width: 100%;
  height: 100%;
  padding: 13px;
  box-sizing: border-box;
}

@media (max-width: 450px) {
  .sc-header {
    border-radius: 0;
  }
}
