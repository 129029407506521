.default-btn {
  display: inline-block;
  padding: 12px 32px;
  color: #ffffff;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-decoration: none !important;
}

.default-btn i {
  font-size: 20px;
  position: relative;
  top: 4px;
  display: none;
}

.default-btn::before {
  content: '';
  position: absolute;
  z-index: -1;
  height: 100%;
  left: -25%;
  top: 0;
  -webkit-transform: skew(50deg);
  transform: skew(50deg);
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  width: 0;
}

.default-btn:hover {
  color: #ffffff !important;
}

.default-btn:hover:before {
  height: 100%;
  width: 135%;
  background-color: #252525;
}

.btn-bg-one {
  background-color: #F354FF;
}

.btn-bg-two {
  background-color: #6F7BFF;
}
