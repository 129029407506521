.sc-user-input {
  min-height: 55px;
  margin: 0;
  position: relative;
  bottom: 0;
  display: flex;
  background-color: #f4f7f9;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
}

.sc-user-input--text {
  width: 320px;
  resize: none;
  border: none;
  outline: none;
  border-bottom-left-radius: 10px;
  box-sizing: border-box;
  padding: 18px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.33;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #565867;
  -webkit-font-smoothing: antialiased;
  max-height: 200px;
  overflow: scroll;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  border-right: 1px solid rgb(0, 0, 0, 20%);
}

.sc-user-input--text:empty::before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: rgb(86, 88, 103, 30%);
  outline: none;
}

.sc-user-input--buttons {
  width: 50px;
  position: absolute;
  right: 1px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.sc-message--avatar {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  align-self: center;
  margin-right: 15px;
}

.sc-user-input--button:first-of-type {
  width: 40px;
}

.sc-user-input--button button {
  cursor: pointer;
}

.sc-user-input--buttons input[type='file'] {
  display: none;
}

.sc-user-input--picker-wrapper {
  display: flex;
  flex-direction: column;
}

.sc-user-input.active {
  background-color: white;
  box-shadow: 0 -5px 20px 0 rgb(150, 165, 190, 20%);
}

.sc-user-input--file-icon,
.sc-user-input--send-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  align-self: center;
  outline: none;
}

.sc-user-input--file-icon path,
.sc-user-input--send-icon path {
  fill: rgb(86, 88, 103, 30%);
}

.sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon circle {
  fill: rgb(86, 88, 103, 30%);
}

.sc-user-input--file-icon:hover path,
.sc-user-input--send-icon:hover path {
  fill: rgb(86, 88, 103, 100%);
}

.sc-user-input--emoji-icon-wrapper,
.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  background: none;
  border: none;
  padding: 2px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sc-user-input--send-icon-wrapper,
.sc-user-input--file-icon-wrapper {
  flex-direction: row;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.send-icon {
  size: 1.5rem;
  color: rgb(139, 137, 137);
}

.send-icon:hover {
  color: #5189f1;
}

.sc-user-input--emoji-icon-wrapper:focus {
  outline: none;
}

.sc-user-input--emoji-icon {
  height: 18px;
  cursor: pointer;
  align-self: center;
}

.sc-user-input--emoji-icon.active path,
.sc-user-input--emoji-icon:hover path,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon path,
.sc-user-input--emoji-icon.active circle,
.sc-user-input--emoji-icon:hover circle,
.sc-user-input--emoji-icon-wrapper:focus .sc-user-input--emoji-icon circle {
  fill: rgb(86, 88, 103, 100%);
}
