* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: white !important;
  height: 90vh;
}
