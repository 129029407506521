.filter {
  font-family: 'Times New Roman ';
}

.lists {
  overflow: scroll;
  float: left;
  min-height: 60vh;
  width: 270px !important;
  margin-left:10px !important;
}

.checkBoxes {
  max-height: 220px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.checkBoxItem {
  margin-left: 15px !important;
  margin-bottom: 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.item-label {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8em !important;
}

.sub-item-label {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.8em !important;
}

.sidefilter-title{
  color: rgb(86, 85, 85);
  font-weight: 600;
}

.sidefilter-subtitle{
  color: rgb(143, 142, 142);
  font-weight: 400;
  font-size: 0.9em;
}

.sidefilter-header{
  position:relative;
  top:0;
}


.sidefilter-body{
   position:relative;
  /* height: 72vh !important; */
  width:100%;
  overflow-y:scroll;
}

.sidefilter-footer{
  position:relative;
  bottom:0;
}

.filter-section{
  border:1px solid
}
