.boxed-widget {
  border-radius: 15px;
  padding: 2em 1em;
  transform: translate3d(0, 0, 0);
  z-index: 90;
  position: relative;
  /*  border: 1px solid #ddd; */
  background-color: #eef1fa;
}

.boxed-widget h3 {
  font-size: 20px;
  padding: 0 0 10px;
  display: block;
}

.boxed-widget h3 img {
  margin-right: 10px;
  color: #0cbcb7;
}

.boxed-widget ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.boxed-widget ul li {
  font-size: 15px;
  font-weight: 500;
  color: #434343 !important;
  width: 100%;
  margin: 10px 15px;
  transition: 0.2s;
  cursor: default;
  /* text-align: justify; */
}

.boxed-widget ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #767676 !important;
  width: 100%;
  /* margin: 10px 10px; */
  /* padding-left: 10px; */
  padding-right: 1.8rem;
  transition: 0.2s;
  cursor: default;
  margin-top: 0px !important;
  text-align: justify;
}

.boxed-widget ul li p {
  font-size: 15px;
  font-weight: 500;
  color: #767676 !important;
  width: 100%;
  margin: 10px 0;
  transition: 0.2s;
  cursor: default;
  margin-top: 0px !important;
}

.card-options img {
  margin: 0 0 0 6px;
}
