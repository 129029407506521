.scroll-to-top {
  position: fixed;
  bottom: 12px;
  left: 3%;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  background: #0071dc;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  line-height: 48px;
  z-index: 100;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin-left: 25px;
}

@-webkit-keyframes scrollTop {
  from {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  to {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
}

@keyframes scrollTop {
  from {
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
  }
  to {
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
  }
}

.scroll-to-top .icon {
  margin-top: 10px;
  -webkit-animation: scrollTop 0.5s alternate ease infinite;
  animation: scrollTop 0.5s alternate ease infinite;
}

.scroll-to-top:hover {
  background-color: #ffc221;
}
