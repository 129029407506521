.loader-wrapper {
  overflow: hidden;
  position: fixed;
  top: calc(100% - 10%);
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100vh - (113px));
}

.loading-widget {
  width: 8vmax;
  height: 8vmax;
  border-right: 4px solid #6495ed;
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
  transform: translate(-50%, -50%);

  &:before,
  &:after {
    content: '';
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid #f354ff;
    border-radius: 100%;
    animation: spinLeft 800ms linear infinite;
  }

  &:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid #6495ed;
    animation: none;
  }
}

@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
