.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 500px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.buttons{
  display: inline-flex;
  margin-left: 80%;
}
.next-btn{
  margin:2%;
}
.previous-btn{
  margin:2%;
}