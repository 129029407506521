.header.header-fixed {
  right: 0 !important;
  left: 0 !important;
}

.body {
  background-color: white !important;
}
.wrapper {
  min-width: 375px; /* Suppose you want minimum width of 1000px */
  width: auto !important; /* Firefox will set width as auto */
}

.remove-link-props {
  text-decoration: none;
}

.grey-color {
  color: grey !important;
}

.pt-20 {
  padding-top: 20px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-70 {
  padding-bottom: 70px;
}
.remove-btn-class {
  border: none !important;
  line-height: 1rem !important;
  --cui-btn-hover-color: #6495ed !important;
  --cui-btn-hover-bg: var(--cui-btn-bg, transparent) !important;
  --cui-btn-hover-border-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-bg: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-border-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-active-color: #6495ed;
  --cui-btn-disabled-color: var(--cui-btn-bg, transparent) !important;
  --cui-btn-shadow: var(--cui-btn-bg, transparent) !important;
}

.get-hover-effect:hover {
  cursor: pointer;
  background-color: transparent;
}

.remove-btn-class:hover {
  text-decoration: underline !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.hover-effect:hover {
  cursor: pointer;
}


.checkboxQuote input[type=checkbox] {
  accent-color: green;
}

.line hr {
  height: 1px;
  border: 0;
  background: black;
}

.tile-title {
  font-weight: 700;
}

.tile-details {
  font-weight: 600;
  color: grey;
}

.control-head-text {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 5px;
  color: #434343 !important;
}
.btn-primary {
  --cui-btn-hover-bg: #6495ed;
}
.custom-links {
  //color: linear-gradient(#0071dc, yellow) !important;
  background: -webkit-linear-gradient(#6f7bff, #f354ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-decoration: none !important;
}
.custom-links:hover {
  color: #ffc107 !important;
  text-decoration: underline !important;
}
.styled-link{
  -webkit-background-clip: text;
  background-clip: text;
    color: rgb(54, 54, 177);
    text-decoration: none !important;
}
.styled-link:hover {
  text-decoration: underline !important;
}
.bold-text {
  font-weight: 700 !important;
}
.small-font{
  font-size: smaller;
}
.heading-small {
  font-size: 15px;
  font-weight: 500;
  color: #767676;
}

.clickable-icon {
  cursor: pointer;
}

.justify-content-flexend {
  display: flex;
  justify-content: flex-end;
}

.fixed-nav {
  // background-color: var(--cui-body-color);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
  margin-bottom: 0px;
}

.btn {
  line-height: 2 !important;
}

.btn-primary {
  background-color: #6495ed !important;
  border-color: #6495ed !important;
  color: #fff !important;

  --cui-btn-shadow: #6495ed !important;
}

.btn-primary:hover {
  background-color: #fff !important;
  border-color: #6495ed !important;
  color: #6495ed !important;
}

.inline-css {
  display: inline-block !important;
  margin-left: 0.3em !important;
}

.clickable-div {
  color: #6495ed;
}

.clickable-div:hover {
  color: #3476f1 !important;
  text-decoration: underline !important;
}

.notifications-icon {
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.1em;
  color: #8ab2fc;
}

.notifications-icon:hover {
  color: rgb(75, 75, 251);
}

.notifications-icon:active {
  color: blue;
}

.border-radius-50 {
  border-radius: 50px;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
  .container-max {
    max-width: 1300px !important;
    margin: 0 auto;
  }
}

.pl20 {
  padding-left: 20%;
}

.pl40 {
  padding-left: 40%;
}

.pl60 {
  padding-left: 60%;
}

.remove-margin {
  margin: 0 !important;
}

.primary-hover {
  color: #ffc221;
}

.primary-hover:hover {
  color: #ffc221;
}



