.d-tree-toggler.active {
  transform: rotate(180deg) !important;
  /* margin-right: 10px; */
  /* margin-left: -11px !important; */
}
.checkBox {
  margin-left: 16px;
}
/* .AiFillCaretUp {
  margin-right: 10px !important;
} */

.filter-list {
  width: 100% !important;
  font-size: 1.2em;
  border: 1px solid darkgrey;
  margin-bottom: 10px !important;
  border-radius: 8px;
}
